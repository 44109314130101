import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Location } from '@app/objects/Location';
import { Crematory } from '@app/objects/Crematory';

import { WithAddress } from '@app/objects/WithAddress';
import { ClinicCrematory } from '@app/objects/ClinicCrematory';
import { User } from '@app/objects/User';
import { Language } from '@app/objects/Language';
import { Country } from '@app/objects/Country';
import { DeliveryType, ServiceType } from '@app/objects/Pet';
import { State } from '@app/objects/State';

export enum Customer {
	All = -1,
	NonCustomer = 0,
	Customer = 1,
}

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

export interface Clinic extends WithDeleted, WithAddress {
	name: string;

	contactFirstName: string;
	contactLastName: string;

	email: string;
	phone: string;

	note: string;

	locations: Array<Location>;
	crematories?: Array<Crematory>;
	clinicUsers: Array<User>;
	clinicCrematories: Array<ClinicCrematory>;

	languageId: number;
	language: Nullable<Language>;

	countryId: number;
	country: Nullable<Country>;

	dateFormat: string;
	timeFormat: string;

	canPutOnRush: boolean;
	canPutOnHold: boolean;
	canSetDeliveryType: boolean;
	defaultPrivateDeliveryType: DeliveryType;
	defaultSemiPrivateDeliveryType: DeliveryType;
	defaultCommunalDeliveryType: DeliveryType;

	clinicType: Nullable<ClinicType>;
	clinicTypeId: Nullable<number>;
}

export interface ClinicListInfo extends WithId {
	name: string;
	contactFirstName: string;
	contactLastName: string;
	streetAddress: string;
	streetAddress2: string;
	city: string;
	zip: string;
	crematories: Array<ClinicCrematory>;
	countryId?: number;

	state: Nullable<State>;
	stateId: Nullable<number>;
}

export interface ClinicSelectInfo extends WithId {
	name: string;
	countryId: number;
	isCustomer: boolean;

	defaultPrivateDeliveryType: DeliveryType;
	defaultSemiPrivateDeliveryType: DeliveryType;
	defaultCommunalDeliveryType: DeliveryType;
	defaultStorageDeliveryType: DeliveryType;
}

export function getDefaultClinic(): Clinic {
	return {
		id: -1,

		name: '',

		streetAddress: '',
		streetAddress2: '',
		city: '',
		zip: '',

		state: null,
		stateId: null,

		contactFirstName: '',
		contactLastName: '',
		email: '',
		phone: '',

		clinicUsers: [],
		clinicCrematories: [],
		crematories: [],
		locations: [],

		note: '',

		languageId: -1,
		language: null,

		countryId: -1,
		country: null,

		dateFormat: 'MM/dd/YYYY',
		timeFormat: 'hh:mm a',

		canPutOnRush: false,
		canPutOnHold: false,
		canSetDeliveryType: false,
		defaultPrivateDeliveryType: DeliveryType.None,
		defaultSemiPrivateDeliveryType: DeliveryType.None,
		defaultCommunalDeliveryType: DeliveryType.None,

		clinicType: null,
		clinicTypeId: null,
	};
}

export interface ClinicDiscount extends WithId {
	name: string;
	description: string;
	value: number;
	serviceType: ServiceType;
	appliesTo: ApplyFilter;
	clinicId: number;
	crematoryId: number;
}

export enum ApplyFilter {
	BasePrice = 0,
	ServicePrice = 1,
}

export interface ClinicType extends WithId {
	name: string;
	description: string;
	sourceId: Nullable<number>;
	crematoryId: Nullable<number>;
}
