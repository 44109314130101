import { Nullable } from '@common/typescript/objects/Nullable';
import { isPresent } from '@common/typescript/objects/WithRemoved';

import {
	ServiceType,
	PetPrice,
	WeightSource,
	DeliveryType,
	PetEngraving,
	PetInvoiceSummary,
} from '@app/objects/Pet';
import {
	PriceType,
	PriceKind,
} from '@app/objects/Price';
import { InventoryItem } from '@app/objects/Inventory';

export interface WeightContainer {
	actualWeight: number;
	reportedWeight: number;
}

export interface ProductContainer {
	removed?: boolean;
	categoryId: number;
	count: number;
	value?: number;
}

export interface KeyPriceParamsContainer {
	id: number;
	weight: number;
	rush: boolean;
	engraving: Array<PetEngraving>;

	crematoryId: number;
	clinicId: Nullable<number>;

	priceType: PriceType;
	serviceType: ServiceType;

	petSpecieId: number;

	deliveryType: DeliveryType;

	urns: Array<PetPrice>;
	discountId: Nullable<number>;

	summary: PetInvoiceSummary;
}

export interface KeyNameContainer {
	inventoryItem: InventoryItem;
}

/**
 * Get current weight
 * @param {WeightContainer} item - item containing actual and reported weight data
 * @param {WeightSource} source - which weight-picking policy to use
 * @returns {number} current weight
 */
export function getWeight(item: WeightContainer, source: WeightSource): number {
	if (source === WeightSource.ReportedWeight) return item.reportedWeight;

	return item.actualWeight > 0 ? item.actualWeight : item.reportedWeight;
}

function compareServices(original: Array<PetPrice>, current: Array<PetPrice>): boolean {
	if (original.length !== current.length) return false;

	return current.reduce((res: boolean, item: PetPrice) => {
		const source = original.find((q: PetPrice) => q.clientId === item.clientId);

		return res && Boolean(source)
			&& source.value === item.value
			&& source.count === item.count
			&& source.priceId === item.priceId
			&& Boolean(source.removed) === Boolean(item.removed);
	}, true);
}

function filterServices(services: Array<PetPrice> = []): Array<PetPrice> {
	return services.filter((q: PetPrice) => q.price?.priceKind === PriceKind.SpecialServicePrice || q.price?.priceKind === PriceKind.PickupPrice);
}

type PriceComparable = KeyPriceParamsContainer & { services?: Array<PetPrice>, products?: Array<PetPrice> };

function hasValue(item: PetEngraving): boolean {
	return isPresent(item) && Boolean(item.name);
}

const keys: Array<keyof KeyPriceParamsContainer> = [
	'weight',
	'rush',
	'crematoryId',
	'clinicId',
	'priceType',
	'serviceType',
	'petSpecieId',
	'deliveryType',
	'discountId',
];

/**
 * Should price object be kept or should base price be recalculated
 * @param {KeyPriceParamsContainer} original - original object
 * @param {KeyPriceParamsContainer} current - updated object
 * @returns {boolean} - true if price should be kept
 */
export function shouldKeepPrice(original: PriceComparable, current: PriceComparable): boolean {
	return keys.reduce(
		(res: boolean, key: keyof KeyPriceParamsContainer) => res && (original[key] === current[key]),
		compareServices(original.urns ?? [], current.urns ?? [])
		&& compareServices(original.products ?? [], current.products ?? [])
		&& compareServices(filterServices(original.services), filterServices(current.services))
		&& original.engraving.filter(hasValue).length === current.engraving.filter(hasValue).length,
	);
}
