export const RoutePaths = {
	admin: '/admin/:tab?',

	clinics: '/clinics/:tab',
	clinicList: '/clinics/clinic-list/:page?',
	invoiceList: 'clinics/invoice-list/:page?',
	routeList: '/clinics/routes/:page?',
	clinicEditor: '/clinics/clinic-editor/:id/:type/:tab',
	clinicPreferences: '/clinic-preferences/:type?',

	crematoryList: '/crematory-list/:page?',
	crematoryEditor: '/crematory-editor/:id/:type/:tab',
	crematories: '/crematories',
	chemicalsTableBuilder: '/chemicals-table-builder/:id/:type?',

	userList: '/user-list/:page?',
	userEditor: '/user-editor/:id',

	crematoryUsers: '/crematory-users-list/:page?',
	crematoryUserEditor: '/crematory-user-editor/:id',
	apiKeys: '/crematory-user-editor/:id/api-keys',

	complaints: '/complaints/:page?',
	carriers: '/carriers/:page?',
	discounts: '/discounts/:page?',
	dashboard: '/dashboard',
	petSpecies: '/pet-species/:page?',
	petBreeds: '/pet-breeds/:page?',
	gender: '/pet-gender/:page?',
	specialServices: '/special-services/:page?',
	pickupServices: '/pickup-services/:page?',
	inventoryTemplate: '/inventory-template/:page?',
	invoiceEditor: '/clinics/invoice-editor/:id/:petId?',
	routeEditor: '/clinics/route-editor/:id',
	prices: '/prices/:page?',
	statusesList: '/statuses-list/:page?',
	measuringUnits: '/measuring-units/:page?',
	profile: '/profile',
	registration: '/registration',
	confirm: '/confirm',
	recover: '/recover',
	login: '/login',

	pets: '/pets/:tab?/:page?',
	petList: '/pets/pet-list/:page?',
	petEditor: '/pets/pet-editor/:id/:type/:tab?',
	batchList: '/pets/batch-list/:page?',
	batchEditor: '/pets/batch-editor/:id',
	apiImportDetails: '/pets/api-import/:id',

	inventory: '/inventory/:tab?/:page?/:path(.*)?',
	adjustmentList: '/inventory/adjustment-list',
	inventoryItemList: '/inventory/stock-list',
	purchaseOrderList: '/inventory/purchase-order-list',
	purchaseOrderEditor: '/inventory/purchase-order/:id',
	storeEntries: '/inventory/item-list',
	storeEntryEditor: '/inventory/item-list/:id/:type',

	petListStatusDetails: '/pet-list-status-details/:id/:page?',
	petListSpecialServiceDetails: '/pet-list-special-service-details/:id/:page?',

	pickupReports: '/pickup-report-list',
	reportsList: '/reports-list',
	averageTurnAround: '/reports-list/average-turn-around-report',
	averageTurnAroundDetails: '/reports-list/average-turn-around-report/details',
	incomeReport: '/reports-list/income-report',
	urnReport: '/reports-list/urn-report',
	clinicInvoiceReport: '/reports-list/clinic-invoice-report',

	requestItems: '/request-items',
	labelPaperEditor: '/admin/label-paper/editor/:id/:type',
	complaintEditor: '/admin/complaints/editor/:id',

	quickbooks: '/quickbooks/:tab?',
	customerList: '/quickbooks/customer-list/:page?',
	quickbooksItemList: '/quickbooks/item-list/:page?',
	quickbooksItemEditor: '/quickbooks/item-list/editor/:id',

	countriesEditor: '/admin/countries/:id',
};

export function getPath(root: keyof (typeof RoutePaths)): string {
	return RoutePaths[root].replace(/:.+\?/g, '');
}

export const getPathWithParams = (pathName: string, item: string) => {
	const newPath = pathName.substring(0, pathName.lastIndexOf('/') + 1);

	return newPath + item;
};

export const getPathTabs = (pathName: string, item: string) => {
	const newPath = pathName.substring(0, pathName.lastIndexOf('/') + 1);

	return `${newPath}?tab=${item}`;
};
