import * as React from 'react';
import { useSelector } from 'react-redux';

import Table, { ColumnProps } from 'antd/lib/table';

import {
	Field, FieldProps, useFormikContext,
} from 'formik';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';

import { Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { Price } from '@app/objects/Price';
import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { useModal } from '@app/hooks/Editors/useModal';
import { getUserName } from '@app/components/Utils/Utils';
import { PetPrice, filterServices } from '@app/objects/Pet';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { ServiceIndexProvider } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/ServiceIndexProvider';
import { SpecialServiceDialog, getPetPrice } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceDialog';
import { KitServiceTable } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/KitServiceTable';

import '@app/scss/ui/components/tables.scss';
import '@app/scss/ui/modals.scss';
import '@app/scss/ui/filters.scss';

export interface SpecialServiceActions {
	add: (items: Array<PetPrice>) => void;
	update: (id: string, value: Partial<PetPrice>) => void;
	delete: (id: string) => void;
}

interface SpecialServiceListProps {
	prices: Array<Price>;
	actions: SpecialServiceActions;
	loading: boolean;
}

interface ProductPriceValues {
	value: number;
	extra: number;
}
export function getPriceValue(item: Price, count: number = 1): ProductPriceValues {
	const batchPrice = item.batchPrice ?? 0;
	const batchCount = item.batchCount ?? 0;

	const extra = Math.max((count - batchCount) * (item.value ?? 0));

	return {
		value: batchPrice + extra,
		extra,
	};
}

interface ServiceItemError {
	count: string;
	value: string;
}

// Pet services list columns
function getColumns(
	actions: SpecialServiceActions,
	selection: Array<number>,
	onChange: (value: Array<number>) => void,
): Array<ColumnProps<PetPrice>> {
	return TableBuilder.shape<PetPrice>()
		.addColumn({
			title: <b>Count</b>,
			width: '150px',
			dataIndex: 'count',
			render: (_, record: PetPrice) => (
				<ServiceIndexProvider clientId={record.clientId}>
					{
						(index: Nullable<number>) => {
							if (index === null) return <div>-</div>;

							return (
								<Field name={`services.${index}.count`}>
									{
										({ field, form, meta }) => {
											const error = form.errors.services?.[index] as ServiceItemError | undefined;
											const touched = form.touched.services?.[index]?.value;
											const cls = Boolean(form.submitCount) && error && touched && error.value
												? 'table-cell-error'
												: '';

											const onChange = (value: number) => {
												if (!record.price) return;

												const count = value;
												const currentValue = getPriceValue(record.price, count);

												actions.update(record.clientId, {
													count,
													value: currentValue.value,
													extra: currentValue.extra,
												});
											};

											return (
												<FormikInput
													fieldProps={{ field, form, meta }}
													containerClassName={cls}
													render={({ field }: FieldProps<number, PetFormValues>) => (
														<NumberField
															{...field}
															onChange={onChange}
															disabled={record.done || record.completedCount > 0}
														/>
													)}
												/>
											);
										}
									}
								</Field>
							);
						}
					}
				</ServiceIndexProvider>
			),
		})
		.addColumn({
			title: <b>Name</b>,
			dataIndex: 'name',
		})
		.addColumn({
			title: <b>Notes</b>,
			dataIndex: 'note',
			render: (_, record: PetPrice) => (
				<ServiceIndexProvider clientId={record.clientId}>
					{
						(index: Nullable<number>) => {
							if (index === null) return <div>-</div>;

							return (
								<Field name={`services.${index}.note`}>
									{
										({ field, form, meta }) => (
											<div className="row">
												<FormikInput
													fieldProps={{ field, form, meta }}
													inputProps={{ disabled: !record.price?.specialService?.hasNote || record.done }}
													containerClassName="col-sm-12"
												/>
											</div>
										)
									}
								</Field>
							);
						}
					}
				</ServiceIndexProvider>
			),
		})
		.addColumn({
			title: <b>Cost</b>,
			width: '150px',
			dataIndex: 'value',
			render: (_, record: PetPrice) => (
				<ServiceIndexProvider clientId={record.clientId}>
					{
						(index: Nullable<number>) => {
							if (index === null) return <div>-</div>;

							return (
								<Field name={`services.${index}.value`}>
									{({ field, form, meta }: FieldProps<number, PetFormValues>) => {
										const error = form.errors.services?.[index] as ServiceItemError | undefined;
										const touched = form.touched.services?.[index]?.value;
										const cls = Boolean(form.submitCount) && error && touched && error.value
											? 'table-cell-error'
											: '';

										return (
											<FormikInput
												fieldProps={{ field, form, meta }}
												containerClassName={cls}
												render={({ field }: FieldProps<number, PetFormValues>) => (
													<NumberField
														{...field}
														onChange={(value: number) => actions.update(record.clientId, { value })}
														disabled={record.done || record.completedCount > 0}
													/>
												)}
											/>
										);
									}}
								</Field>
							);
						}
					}
				</ServiceIndexProvider>
			),
		})
		.addColumn({
			title: <b>Status</b>,
			dataIndex: 'done',
			render: (value: boolean) => (value ? 'Completed' : 'Not completed'),
		})
		.addColumn({
			align: 'left',
			title: <b>Changed by</b>,
			render: (_, record: PetPrice) => {
				if (record.editorId === null) return '-';
				if (record.editor === null) return 'Unknown user';

				return getUserName(record.editor);
			},
		})
		.addColumn({
			align: 'right',
			title: '',
			render: (_, record: PetPrice) => {
				if (record.done || record.completedCount > 0) return null;

				return (
					<Button
						type="text"
						danger
						className="action-container"
						onClick={() => {
							actions.delete(record.clientId);
							onChange(selection.filter((s) => s !== record.priceId));
						}}
						icon={<DeleteOutlined />}
					/>
				);
			},
		})
		.build();
}

function pickSelection(items?: Array<PetPrice>): Array<number> {
	if (!items?.length) return [];

	return items.filter((item: PetPrice) => !item.removed).map((item: PetPrice) => item.priceId);
}

function getKits(record: PetPrice, items: Array<PetPrice>, prices: Array<Price>) {
	if (record.id > 0) return items.filter((i) => i.price?.specialService?.parentId === record.price?.specialServiceId);

	return getPetPrice(prices.filter((i) => i.specialService?.parentId === record.price?.specialServiceId), record.petId);
}

export const expendedProps = (items: Array<PetPrice>, prices: Array<Price>) => ({
	expandedRowRender: (record: PetPrice) => <KitServiceTable serviceKits={getKits(record, items, prices)} />,
	rowExpandable: (record: PetPrice) => record.price?.specialService?.specialServiceType === SpecialServiceType.Kit,
});

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SpecialServiceList: React.FC<SpecialServiceListProps> = (props: SpecialServiceListProps) => {
	const { values } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const modal = useModal(false);

	const items = React.useMemo(() => filterServices(values.services ?? []), [values.services]);
	const [selection, setSelection] = React.useState<Array<number>>(pickSelection(items));

	React.useEffect(() => {
		setSelection(pickSelection(items));
	}, [items]);

	if (user?.role !== UserRole.Crematory && values.crematoryId < 1) return <i><b>Crematory should be selected</b></i>;

	return (
		<>
			<div className="table-controls__container">
				<div className="table-controls__action-container pull-right">
					<div className="table-controls__action">
						<Button
							type="link"
							onClick={modal.open}
							icon={<PlusOutlined />}
						>
							Add Service
						</Button>
					</div>
				</div>
			</div>
			<Table
				columns={getColumns(props.actions, selection, setSelection)}
				dataSource={(items ?? []).filter((q: PetPrice) => !q.removed && !!q.price?.specialServiceId)}
				size="small"
				rowKey="clientId"
				pagination={false}
				loading={props.loading}
				{...expendedProps(values.services?.filter((q: PetPrice) => !q.removed) ?? [], props.prices)}
			/>

			<SpecialServiceDialog
				modal={modal}

				selection={selection}
				onChangeSelection={setSelection}

				items={filterServices(values.services ?? [])}
				crematoryId={values.crematoryId}
				clinicId={values.clinicId}
				priceType={values.priceType}
				serviceType={values.serviceType}
				petId={values.id}
			/>
		</>
	);
};
