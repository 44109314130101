import * as React from 'react';
import { useSelector } from 'react-redux';
import '@app/scss/ui/cards/tabs.scss';

import TabsWithUrl from '@common/react/components/UI/TabsWithUrl/TabsWithUrl';
import { loadable, loadableDelay, params } from '@common/react/loadable/loadableSettings';

import { Pet, PetSource } from '@app/objects/Pet';
import {
	OldPetInventoryUsageTab,
	OldPetImportNotesTab,
	OldPetHistoryChangeTab,
	OldPetStatusLogTab,
	OldPetEmailLogTab,
} from '@app/components/Pages/PetEditor/OldPetEditorTabs';
import { ApplicationState } from '@app/store';
import { Tab } from '@app/objects/Tab';

const LoadableCommentTree = loadable(() => loadableDelay(import('@common/react/components/Forms/Comment/CommentTree')), params);

interface ComponentProps {
	pet: Pet;
}

const getItems = (pet: Pet, enableApiImport?: boolean): Array<Tab> => {
	const items: Array<Tab> = [
		{
			key: 'notes',
			label: 'Notes',
			children: (
				<LoadableCommentTree
					objectType="pet"
					objectId={pet.id}
					withReply={false}
					withoutCancelButton
					insertBefore
				/>
			),
			className: 'card-tab',
		},
		{
			key: 'status',
			label: 'Status Log',
			children: <OldPetStatusLogTab pet={pet} />,
			className: 'card-tab',
		},
		{
			key: 'email',
			label: 'Email Log',
			children: <OldPetEmailLogTab petId={pet.id} />,
			className: 'card-tab',
		},
		{
			key: 'history',
			label: 'Change History',
			children: <OldPetHistoryChangeTab pet={pet} />,
			className: 'card-tab',
		},
		{
			key: 'inventory',
			label: 'Inventory Usage',
			children: <OldPetInventoryUsageTab pet={pet} />,
			className: 'card-tab',
		},
	];

	if (enableApiImport) {
		items.push({
			key: 'import-notes',
			label: 'Import Notes',
			children: <OldPetImportNotesTab pet={pet} />,
			className: 'card-tab',
			disabled: pet.petSource !== PetSource.External,
		});
	}

	return items;
};

export const OldPetEditorTabs: React.FC<ComponentProps> = (props: ComponentProps): JSX.Element => {
	const { pet } = props;
	const crematoryFeatures = useSelector((state: ApplicationState) => state.crematoryFeatures.item);

	return (
		<div className="card">
			<div className="card__body">
				<TabsWithUrl
					defaultKey="notes"
					tabsUrlKey="tab"
					className="ant-tabs-space"
					items={getItems(pet, crematoryFeatures?.enableApiImport)}
				/>
			</div>
		</div>
	);
};
