import { TFunction } from 'react-i18next';

import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import {
	PetSpecie,
	ServiceType,
	DeliveryType,
} from '@app/objects/Pet';
import { Crematory } from '@app/objects/Crematory';
import { SpecialService } from '@app/objects/SpecialService';
import { Clinic } from '@app/objects/Clinic';
import { InventoryItem } from '@app/objects/Inventory';

export enum PriceKind {
	ClinicDiscount = -1,
	BasePrice = 0,
	SpecialServicePrice = 1,
	DeliveryPrice = 2,
	UrnPrice = 3,
	Discount = 4,
	ProductPrice = 7,
	Engraving = 8,
	PickupPrice = 9,
	RushFee = 10,
}

export enum PriceType {
	All = -1,
	Wholesale = 0,
	Retail = 1,
}

export enum DiscountType {
	Value = 0,
	Percentage = 1,
}

export interface Price extends WithId {
	name: string;
	description: string;
	unit: Nullable<DiscountType>;
	applyTo?: Nullable<PriceKind>;

	petSpecieId: Nullable<number>;
	petSpecie: Nullable<PetSpecie>;

	crematoryId: number;
	crematory: Nullable<Crematory>;

	clinicId: Nullable<number>;
	clinic: Nullable<Clinic>;

	specialServiceId: Nullable<number>;
	specialService: Nullable<SpecialService>;

	pickupServiceId: Nullable<number>;

	inventoryItem: Nullable<InventoryItem>;
	inventoryItemId: Nullable<number>;

	priceType: PriceType;
	serviceType: ServiceType;
	priceKind: PriceKind;
	deliveryType: Nullable<DeliveryType>;

	from: number;
	to: number;

	value: number;
	extra: number;

	batchPrice: number;
	batchCount: number;
	isDefault: boolean;

	order?: Nullable<number>;
	maxSize?: Nullable<number>;
}

// Copy prices
export interface CopyOptions {
	serviceType: ServiceType;
	priceType: PriceType;
}

export interface CopyPriceActor	{
source: SourcePriceMessage;
destination: DestinationPriceMessage;
}
export interface SourcePriceMessage {
	priceType: PriceType;
	serviceType: ServiceType;
	id: Array<number>;
	name: string;
	selectAll: boolean;
	priceKind: PriceKind;
}
export interface DestinationPriceMessage {
	priceType: PriceType;
	serviceType: ServiceType;
	clinicId?: Nullable<number>
}

export function getPriceTypeName(priceType: PriceType, t: TFunction): string {
	switch (priceType) {
	case PriceType.All:
		return t('enums.price-type.all');
	case PriceType.Wholesale:
		return t('enums.price-type.wholesale');
	case PriceType.Retail:
		return t('enums.price-type.retail');

	default: return 'Unknown price type';
	}
}

export function getPriceKindName(priceKind: PriceKind): string {
	switch (priceKind) {
	case PriceKind.BasePrice:
		return 'Base Price';

	case PriceKind.SpecialServicePrice:
		return 'Special Service';

	case PriceKind.PickupPrice:
		return 'Pickup Service';

	case PriceKind.DeliveryPrice:
		return 'Delivery Price';

	case PriceKind.UrnPrice:
		return 'Urn';

	case PriceKind.ProductPrice:
		return 'Product';

	case PriceKind.Discount:
		return 'Discount';

	case PriceKind.Engraving:
		return 'Engraving';
	case PriceKind.RushFee:
		return 'Rush Fee';

	default:
		return 'Unknown Price Kind';
	}
}

export function getDefaultPrice(crematoryId: number): Price {
	return {
		id: -1,

		name: '',
		description: '',
		unit: DiscountType.Value,

		clinic: null,
		clinicId: null,
		crematory: null,
		crematoryId,

		priceKind: PriceKind.BasePrice,
		priceType: PriceType.Wholesale,
		serviceType: ServiceType.Communal,

		deliveryType: null,
		inventoryItem: null,
		inventoryItemId: null,
		petSpecie: null,
		petSpecieId: null,
		specialService: null,
		specialServiceId: null,
		pickupServiceId: null,

		batchCount: 0,
		batchPrice: 0,
		isDefault: false,

		value: 0,
		extra: 0,
		from: 0,
		to: 0,

		order: 0,
		maxSize: 0,
	};
}
