export const removeFromArrayByIndex = (arr: any[], index: number): any[] => [...arr.slice(0, index), ...arr.slice(index + 1)];

export const removeFromArrayByParam = <T>(arr: T[], paramName: keyof T, value: any): T[] => 
	arr.filter(ot => ot[paramName] !== value);

export const insertToArray = <T>(arr: T[], newItem: T): T[] => [newItem, ...arr];

export const insertToArrayByIndex = <T>(arr: T[], newItem: T, index: number): T[] =>  [...arr.slice(0, index), newItem, ...arr.slice(index)];

export const pushToArray = <T>(arr: T[], newItem: T): T[] => [...arr, newItem];

type updateCompare<T> = ((item: T, values: Partial<T>) => boolean) | keyof T;

export const updateArrayItem = <T>(arr: T[], compare: updateCompare<T>, values: Partial<T>): T[] => {
	const isEqual = typeof compare === 'function' ? compare : (item, values) => item[compare] === values[compare];
	
	return arr.map(item => isEqual(item, values) ? {...item, ...values}  : item);
};
