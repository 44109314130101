import * as React from 'react';

import MaskedInput from 'react-text-mask';
import Input from 'antd/lib/input';

import { phoneMask } from '@app/components/Utils/Utils';

interface PhoneInputProps {
	phoneCode?: string;
	name: string;
	value: string;
	onChange: (value: string) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const defaultCode = '+1';
export const newZealandCode = '+64';
export const australiaCode = '+61';

export const PhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
	const code = props.phoneCode ?? defaultCode;

	const isSpecialCode = code === newZealandCode || code === australiaCode;
	const phoneCodeRef = React.useRef<string>(defaultCode);

	React.useEffect(() => {
		let phone = props.value.replace(phoneCodeRef.current, code);

		if (isSpecialCode && code !== phoneCodeRef.current) {
			const tel = phone.replace(/(\(\)-\s)/g, '');

			phone = `${tel.slice(0, code.length)} ${tel.slice(code.length)}`;
		}

		props.onChange(phone);
		phoneCodeRef.current = code;
	}, [code]);

	if (isSpecialCode) {
		return (
			<Input
				{...props}
				placeholder={code}
				onFocus={() => { if (!props.value.trim()) props.onChange(`${code} `); }}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const value = e.currentTarget.value;

					if (!value.startsWith(code)) return;

					props.onChange(e.currentTarget.value);
				}}
				type="tel"
			/>
		);
	}

	return (
		<MaskedInput
			{...props}
			mask={phoneMask(code)}
			className="form-control"
			placeholder={`${code} (___) ___-____`}
			onChange={(e) => props.onChange(e.currentTarget.value)}
			id={props.name}
			type="tel"
		/>
	);
};
