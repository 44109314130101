import * as React from 'react';

import Modal from 'antd/lib/modal';

interface OwnProps {
	title: string;
	isOpened: boolean;
	onOk: () => void;
	onCancel?: () => void;

	okText?: string;
	cancelText?: string;
	confirmLoading?: boolean;

	width?: string | number;

	okButtonProps?: Record<string, unknown>;
	modalClassName?: string;
	style?: React.CSSProperties;
	footer?: React.ReactNode;
}

export const ModalInner: React.FC<OwnProps> = (props) => {
	const {
		title, okText = 'Add', cancelText = 'Cancel',
		isOpened, onOk, confirmLoading = false, onCancel,
		footer = undefined,
	} = props;

	const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);

	const openDialog = () => setDialogOpened(true);
	const closeDialog = () => {
		if (onCancel) {
			onCancel();
		}
		setDialogOpened(false);
	};

	React.useEffect(() => {
		if (isOpened) {
			openDialog();
		} else {
			closeDialog();
		}
	}, [isOpened]);

	return (
		<Modal
			title={title}
			open={isDialogOpened}
			onCancel={closeDialog}
			destroyOnClose
			onOk={onOk}
			okText={okText}
			cancelText={cancelText}
			confirmLoading={confirmLoading}
			width={props.width}
			okButtonProps={props.okButtonProps}
			className={props.modalClassName}
			style={props.style}
			footer={footer}
		>
			{props.children}
		</Modal>
	);
};

export const ModalWrapper = ModalInner;
