import {
	matchPath,
} from 'react-router-dom';

import { UserRole } from '@app/objects/User';
import { MenuItem } from '@app/objects/MenuItem';
import { getPath, RoutePaths } from '@app/utilities/RoutePathVars';
import { dashboardTitle } from '@app/components/UI/Header/ClinicHeader';

export type MenuList = Record<string, Array<MenuItem>>;
export const menuList: MenuList = {};

function order(a: MenuItem, b: MenuItem) {
	if (!a.name || !b.name) return -1;

	return a.name > b.name ? 1 : -1;
}

menuList[UserRole.Crematory] = [
	{
		path: getPath('admin'),
		name: 'Admin',
	},
	{
		path: getPath('clinicList'),
		name: 'Clinics',
	},
	{
		path: getPath('customerList'),
		name: 'Quickbooks',
	},
	{
		path: getPath('dashboard'),
		name: 'Dashboard',
	},
	{
		path: getPath('inventory'),
		name: 'Inventory',
	},
	{
		path: getPath('pets'),
		name: 'Pets',
	},
	{
		path: getPath('reportsList'),
		name: 'Reports',
	},
	{
		path: getPath('crematoryUsers'),
		name: 'Users',
	},
];

menuList[UserRole.ClinicUser] = [
	{
		path: getPath('dashboard'),
		name: dashboardTitle,
	},
	{
		path: getPath('petList'),
		name: 'Pet Search',
	},
];

menuList[UserRole.ClinicManager] = [
	{
		path: getPath('dashboard'),
		name: dashboardTitle,
	},
	{
		path: getPath('petList'),
		name: 'Pet Search',
	},
	{
		path: getPath('requestItems'),
		name: 'Request Items',
	},
	{
		path: getPath('clinicPreferences'),
		name: 'Clinic Preferences',
	},
	{
		path: getPath('userList'),
		name: 'Clinic users',
	},
];

menuList[UserRole.CrematoryUser] = [
	{
		path: getPath('dashboard'),
		name: 'Dashboard',
	},
	{
		path: getPath('petList'),
		name: 'Pets',
	},
];

menuList[UserRole.Admin] = [
	{
		path: getPath('admin'),
		name: 'Admin',
	},
	{
		path: getPath('clinicList'),
		name: 'Clinics',
	},
	{
		path: getPath('crematoryList'),
		name: 'Crematories',
	},
	{
		path: getPath('dashboard'),
		name: 'Dashboard',
	},
	{
		path: getPath('petList'),
		name: 'Pets',
	},
	{
		path: getPath('userList'),
		name: 'Users',
	},
].sort(order);

export const getMatchedKey = (path: string): string => Object.values(RoutePaths).find((route) => matchPath(path, route)) || '';

export const getSelectedKeys = (path: string): Array<string> => {
	const matchedKey = getMatchedKey(path);
	const selectedKeys: Array<string> = [matchedKey];

	// adds list path for editors to highlight them in left menu
	switch (matchedKey) {
	case RoutePaths.petEditor:
		selectedKeys.unshift(RoutePaths.petList);
		break;
	case RoutePaths.batchEditor:
		selectedKeys.unshift(RoutePaths.petList);
		break;
	case RoutePaths.clinicEditor:
		selectedKeys.unshift(RoutePaths.clinicList);
		break;
	case RoutePaths.crematoryEditor:
		selectedKeys.unshift(RoutePaths.crematoryList);
		break;
	case RoutePaths.invoiceEditor:
		selectedKeys.unshift(RoutePaths.crematoryList);
		break;
	case RoutePaths.userEditor:
		selectedKeys.unshift(RoutePaths.userList);
		selectedKeys.unshift(RoutePaths.crematoryUsers);
		break;
	case RoutePaths.apiKeys:
		selectedKeys.unshift(RoutePaths.crematoryUsers);
		break;
	case RoutePaths.petListStatusDetails:
		selectedKeys.unshift(RoutePaths.dashboard);
		break;
	case RoutePaths.petListSpecialServiceDetails:
		selectedKeys.unshift(RoutePaths.dashboard);
		break;
	case RoutePaths.pickupReports:
		selectedKeys.unshift(RoutePaths.dashboard);
		break;

	default:
		return selectedKeys;
	}

	return selectedKeys;
};
