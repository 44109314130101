import * as React from 'react';

import { useSelector } from 'react-redux';
import message from 'antd/lib/message';
import Button from 'antd/lib/button';
import { BaseButtonProps } from 'antd/lib/button/button';

import { ApplicationState } from '@app/store';
import { alertMessage, MessageType } from '@app/utilities/alert';

interface OwnProps extends BaseButtonProps {
	url: string;
	className?: string;
	disabled?: boolean;
	children: React.ReactNode;
	icon?: React.ReactNode;
}

const key = 'download';
export const ResourceButton: React.FC<OwnProps> = ({
	url, className, disabled, children, icon, ...props
}: OwnProps) => {
	const session = useSelector((state: ApplicationState) => state.login.session);
	const [isLoading, setIsLoading] = React.useState(false);

	const clickEventHandler = React.useCallback(() => {
		if (disabled) return;
		message.loading({ content: 'Generating PDF in progress...', key });
		setIsLoading(true);

		// eslint-disable-next-line compat/compat
		fetch(url, {
			credentials: 'same-origin',
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=utf-8',
				'X-Client-Type': '0',
				'X-Application-Version': globals.version,
				Cookie: `session=${session ?? ''}`,
			},
		})
			.then((response: Body) => response.blob())
			.then((blob: Blob) => {
				const url = URL.createObjectURL(blob);
				message.success({ content: 'Generating is completed!', key, duration: 3 });
				window.open(url);
			})
			.catch((error: string) => alertMessage(MessageType.error, error))
			.finally(() => setIsLoading(false));
	}, [disabled, url]);

	return (
		<Button
			type={props.type}
			className={className}
			size={props.size}
			loading={isLoading}
			icon={icon}
			disabled={disabled || isLoading}
			onClick={clickEventHandler}
			{...props}
		>
			{children}
		</Button>
	);
};
