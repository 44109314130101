import { Nullable } from '@common/typescript/objects/Nullable';

import { SelectList } from '@app/store/SelectList/SelectList';
import {
	SelectRequestProps,
	isEqualClinic,
	isEmpty,
	isEqualCrematory,
	isEqualPetBreeds,
	isEqual,
	isEqualUrn,
	UrnSelectFilter,
	isEqualUrnCategory,
	UrnCategoriesSelectFilter,
	defaultOrder,
	isEqualRegions,
	isEqualSpecialServices,
	isEqualProduct,
	isEqualInventoryItem,
	isEqualAdjustmentCode,
	isEqualMeasuringUnit,
	isEqualCountry,
	isEqualLanguage,
	isEqualUser,
	defaultFilters,
	defaultClinicFilters,
	defaultPetBreedsFilters,
	defaultUrnsFilters,
	defaultUrnCategoriesFilters,
	defaultRegionsFilters,
	defaultProductsFilters,
	defaultInventoryItemsFilters,
	defaultDiscountsFilters,
	isEqualRoute,
	isEqualInventoryParentsCategory,
	defaultLabelPapersFilters,
	isEqualPickupServices,
} from '@app/store/SelectList/SelectsInterfaces';

type SelectListRequest = {
	[Key in keyof SelectList]: SelectRequestProps<Key>;
};

export const selectListRequest: SelectListRequest = {
	clinics: {
		endpoint: 'clinicSelectList',
		isEqual: isEqualClinic,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultClinicFilters,
	},
	clinicTypes: {
		endpoint: 'clinicTypeList',
		isEqual,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	crematories: {
		endpoint: 'crematoryList',
		isEqual: isEqualCrematory,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	petBreeds: {
		endpoint: 'petBreedList',
		isEqual: isEqualPetBreeds,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultPetBreedsFilters,
	},
	status: {
		endpoint: 'statusList',
		isEqual,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	urns: {
		endpoint: 'inventoryItemList',
		isEqual: isEqualUrn,
		isEmpty,
		isValid: (filters: Nullable<UrnSelectFilter>): boolean => Boolean(filters?.parentId && filters?.parentId.length),
		order: defaultOrder,
		defaultFilters: defaultUrnsFilters,
	},
	urnCategories: {
		endpoint: 'urnCategoryList',
		isEqual: isEqualUrnCategory,
		isEmpty,
		isValid: (filters: Nullable<UrnCategoriesSelectFilter>): boolean => {
			if (filters?.crematoryId && filters?.crematoryId <= 0) return false;
			if (filters?.crematoryId === undefined || filters?.crematoryId > 0) return true;

			return Boolean(0);
		},
		order: defaultOrder,
		defaultFilters: defaultUrnCategoriesFilters,
	},
	regions: {
		endpoint: 'stateList',
		isEqual: isEqualRegions,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultRegionsFilters,
	},
	specialServices: {
		endpoint: 'specialServiceList',
		isEqual: isEqualSpecialServices,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	pickupServices: {
		endpoint: 'pickupServiceList',
		isEqual: isEqualPickupServices,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	pickupServicePrices: {
		endpoint: 'priceList',
		isEqual: () => false,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	products: {
		endpoint: 'inventoryItemList',
		isEqual: isEqualProduct,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultProductsFilters,
	},
	inventoryItems: {
		endpoint: 'inventoryItemList',
		isEqual: isEqualInventoryItem,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultInventoryItemsFilters,
	},
	inventoryParents: {
		endpoint: 'inventoryItemList',
		isEqual: isEqualInventoryParentsCategory,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	adjustmentCodes: {
		endpoint: 'adjustmentCodeList',
		isEqual: isEqualAdjustmentCode,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	countries: {
		endpoint: 'countryList',
		isEqual: isEqualCountry,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	discounts: {
		endpoint: 'priceList',
		isEqual: () => false,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultDiscountsFilters,
	},
	measuringUnits: {
		endpoint: 'measuringUnitList',
		isEqual: isEqualMeasuringUnit,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	languages: {
		endpoint: 'languageList',
		isEqual: isEqualLanguage,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	users: {
		endpoint: 'userList',
		isEqual: isEqualUser,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	routes: {
		endpoint: 'routeList',
		isEqual: isEqualRoute,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	labelPapers: {
		endpoint: 'labelPaperList',
		isEqual,
		isEmpty,
		order: defaultOrder,
		defaultFilters: defaultLabelPapersFilters,
	},
	machines: {
		endpoint: 'machineList',
		isEqual,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	storeEntries: {
		endpoint: 'storeEntryList',
		isEqual,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
	serviceTypes: {
		endpoint: 'serviceTypeList',
		isEqual,
		isEmpty,
		order: defaultOrder,
		defaultFilters,
	},
};
