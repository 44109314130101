import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldProps, FormikProps, useFormikContext } from 'formik';
import Select from 'antd/lib/select';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Col } from 'antd/lib/grid';

import Datepicker from '@common/react/components/Forms/Datepicker/Datepicker';

import {
	getDateByTerms, Invoice, InvoiceStatus, InvoiceTaxModel, InvoiceTerms,
} from '@app/objects/Invoice';
import { ClinicProps, FormValues } from '@app/components/Pages/Clinics/ClinicTabs/Invoices/InvoiceEditor/InvoiceEditorProps';
import { ClinicSelectInfo } from '@app/objects/Clinic';
import { ClinicSelect } from '@app/components/UI/Inputs/ClinicSelect';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { ApplicationState } from '@app/store';
import { useUserLocalPreferences } from '@app/hooks/useUserLocalPreferenses';
import { FormikField } from '@app/components/Forms/FormikField';
import { defaultInvoiceTaxModel } from '@app/components/UI/Inputs/LocalSelectOptions';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { addressService } from '@app/services/AddressService';

const { Option } = Select;

const selectKey = 'helpers.select';

const onTermsChange = (invoiceTerm: number, form: FormikProps<FormValues>) => {
	form.setFieldValue('terms', invoiceTerm);
	form.setFieldValue('dueDate', getDateByTerms(invoiceTerm as InvoiceTerms, +form.values.time));
};

export const CreationDateField: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translation');
	const { dateFormat } = useUserLocalPreferences();

	return (
		<Col xs={24} sm={24} md={8}>
			<FormikField
				fieldName="time"
				title={t('table.columns.date')}
				render={({ field, form }: FieldProps<number, FormValues>) => (
					<Datepicker
						value={field.value}
						format={dateFormat}
						antdProps={{
							disabled: form.values.id > 0,
						}}
						onChange={(value) => form.setFieldValue(field.name, value)}
					/>
				)}
			/>
		</Col>
	);
};

export const ClinicField: React.FC<ClinicProps> = ({ clinic, crematoryId, isModeCreateInvoice }): JSX.Element => {
	const { t } = useTranslation('translation');
	const clinics = useSelector((state: ApplicationState) => state.selects.clinics.items);

	return (
		<Col xs={24} sm={24} md={8}>
			<FormikField
				fieldName="clinicId"
				title={`${t('table.columns.clinic')}*`}
				render={({ field, form }: FieldProps<number, FormValues>) => (
					<ClinicSelect
						value={field.value}
						onChange={(id?: number) => {
							const currentClinic = isModeCreateInvoice ? clinics.find((i) => i.id === id) : clinic;
							form.setFieldValue(field.name as keyof FormValues, id, false);
							form.setFieldValue('clinic', currentClinic);
							form.setFieldValue('pets', []);
						}}
						pickDefault={(store: Array<ClinicSelectInfo>) => isPickDefault(store)}
						allowClear={false}
						crematoryId={crematoryId}
						customers
						disabled={!!clinic}
						placeholder={`${t(selectKey)} ${t('table.columns.clinic')}`}
					/>
				)}
			/>
		</Col>
	);
};

export const SelectTermsField: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translation');

	return (
		<Col xs={24} sm={24} md={8}>
			<FormikField
				fieldName="terms"
				title={t('table.columns.terms')}
				render={({ field, form }: FieldProps<InvoiceTerms, FormValues>) => (
					<Select
						showSearch
						optionFilterProp="children"
						onChange={(invoiceTerm: number) => onTermsChange(invoiceTerm, form)}
						value={field.value}
						disabled={form.initialValues.status === InvoiceStatus.Paid}
					>
						<Option disabled value={-1} key={-1}>{`${t(selectKey)} ${t('table.columns.terms')}`}</Option>
						<Option value={InvoiceTerms.DueOnReceipt}>{t('enums.terms.due-on-receipt')}</Option>
						<Option value={InvoiceTerms.Net15}>{t('enums.terms.net-15')}</Option>
						<Option value={InvoiceTerms.Net30}>{t('enums.terms.net-30')}</Option>
						<Option value={InvoiceTerms.Custom}>{t('enums.terms.custom')}</Option>
					</Select>
				)}
			/>
		</Col>
	);
};

export const DueDateField: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translation');
	const { dateFormat } = useUserLocalPreferences();

	return (
		<Col xs={24} sm={24} md={8}>
			<FormikField
				fieldName="dueDate"
				title={t('table.columns.due-date')}
				render={({ field, form }) => (
					<Datepicker
						value={field.value}
						format={dateFormat}
						onChange={(date) => form.setFieldValue(field.name, date)}
						antdProps={{
							placeholder: t('table.columns.due-date'),
							disabled: form.values.terms !== InvoiceTerms.Custom,
						}}
					/>
				)}
			/>
		</Col>
	);
};

export const SelectStatusField: React.FC<{item: Invoice}> = ({ item }): JSX.Element => {
	const { t } = useTranslation('translation');

	return (
		<Col xs={24} sm={24} md={8}>
			<FormikField
				fieldName="status"
				title={t('table.columns.status')}
				render={({ form, field }: FieldProps<InvoiceStatus, FormValues>) => (
					<Select
						onChange={(e:InvoiceStatus) => form.setFieldValue(field.name, e)}
						value={field.value}
						disabled={item.status === InvoiceStatus.Paid}
					>
						<Option disabled value={-1} key={0}>{`${t(selectKey)} ${t('table.columns.status')}`}</Option>
						<Option value={InvoiceStatus.Open}>{t('enums.invoice-status.open')}</Option>
						<Option value={InvoiceStatus.Paid}>{t('enums.invoice-status.paid')}</Option>
					</Select>
				)}
			/>
		</Col>
	);
};

export const PdfField = ({
	isChecked, isDisable = false, onChange, title,
}): JSX.Element => {
	if (!isChecked)onChange(isChecked);

	return (
		<Checkbox
			checked={isChecked}
			onChange={(value: CheckboxChangeEvent) => onChange(value.target.checked)}
			disabled={isDisable}
		>
			{title}
		</Checkbox>
	);
};

export const InvoiceTaxModelField: React.FC = () => {
	const { t } = useTranslation('translation');
	const { values } = useFormikContext<FormValues>();
	const crematories = useSelector((state: ApplicationState) => state.selects.crematories.items);
	const crematory = crematories.find((i) => i.id === values.crematoryId);
	const details = addressService.provide(crematory?.country);

	return (
		<Col xs={24} sm={24} md={8}>
			<FormikField
				fieldName="invoiceTaxModel"
				title={t('table.columns.invoice-model', { context: details.taxKey })}
				render={({ field, form }: FieldProps<InvoiceTaxModel, FormValues>) => (
					<LocalSelect
						value={field.value}
						onChange={(value: number) => form.setFieldValue(field.name, value, false)}
						options={Object.values(defaultInvoiceTaxModel(t, details.taxKey))}
						deselectType=""
						filterName="invoiceTaxModel"
						fieldName=""
						placeholder={`Select the invoice ${details.taxKey} model`}
						allowClear={false}
					/>
				)}
			/>
		</Col>
	);
};
