import { Nullable } from '@common/typescript/objects/Nullable';

import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { PetPrice } from '@app/objects/Pet';
import { PriceKind } from '@app/objects/Price';
import { Values } from '@app/services/pricing/PricingManager';

/**
 * This PriceStack is used to display data of saved (existing) prices
 */
export class ExistingPriceStack implements IPriceStack {
	private readonly _base: Nullable<PetPrice> = null;
	private readonly _engraving: Array<PetPrice> = [];
	private readonly _services: Array<PetPrice> = [];
	private readonly _urns: Array<PetPrice> = [];
	private readonly _products: Array<PetPrice> = [];
	private readonly _discount: Nullable<PetPrice> = null;
	private readonly _clinicDiscounts: Array<PetPrice> = [];
	private readonly _delivery: Nullable<PetPrice> = null;
	private readonly _rush: Nullable<PetPrice> = null;

	private readonly _serviceValue: number = 0; // this is services + base (with discount included)
	private readonly _productValue: number = 0;
	private readonly _productTaxValue: number = 0;
	private readonly _productTaxPercentage: number = 0;
	private readonly _serviceTaxValue: number = 0;
	private readonly _serviceTaxPercentage: number = 0;
	private readonly _subtotal: number = 0;
	private readonly _total: number = 0;

	public constructor(services: Array<PetPrice> = [], values: Nullable<Values> = null) {
		this._base = services.find((q: PetPrice) => q.price?.priceKind === PriceKind.BasePrice) ?? null;
		this._engraving = services.filter((q: PetPrice) => q.price?.priceKind === PriceKind.Engraving);
		this._services = services.filter((q: PetPrice) =>
			q.price?.priceKind === PriceKind.SpecialServicePrice || q.price?.priceKind === PriceKind.PickupPrice);
		this._urns = services.filter((q: PetPrice) => q.price?.priceKind === PriceKind.UrnPrice);
		this._products = services.filter((q: PetPrice) => q.price?.priceKind === PriceKind.ProductPrice);
		this._discount = services.find((q: PetPrice) => q.price?.priceKind === PriceKind.Discount) ?? null;
		this._clinicDiscounts = services.filter((item: PetPrice) => item.price?.priceKind === PriceKind.ClinicDiscount);
		this._delivery = services.find((q: PetPrice) => q.price?.priceKind === PriceKind.DeliveryPrice) ?? null;
		this._rush = services.find((q: PetPrice) => q.price?.priceKind === PriceKind.RushFee) ?? null;

		this._productValue = values?.summary?.productsTotal ?? 0;
		this._serviceValue = values?.summary.servicesTotal ?? 0;
		this._productTaxValue = values?.summary?.productTax ?? 0;
		this._serviceTaxValue = values?.summary?.serviceTax ?? 0;
		this._productTaxPercentage = (values?.summary.productTaxPercentage ?? 0) / 100;
		this._serviceTaxPercentage = (values?.summary.serviceTaxPercentage ?? 0) / 100;
		this._subtotal = values?.summary.subTotal ?? 0;
		this._total = values?.summary.total ?? 0;
	}

	public get base(): Nullable<PetPrice> {
		return this._base;
	}

	public get engravings(): Array<PetPrice> {
		return this._engraving;
	}

	public get services(): Array<PetPrice> {
		return this._services;
	}

	public get urns(): Array<PetPrice> {
		return this._urns;
	}

	public get products(): Array<PetPrice> {
		return this._products;
	}

	public get discount(): Nullable<PetPrice> {
		return this._discount;
	}

	public get clinicDiscounts(): Array<PetPrice> {
		return this._clinicDiscounts;
	}

	public get delivery(): Nullable<PetPrice> {
		return this._delivery;
	}

	public get rush(): Nullable<PetPrice> {
		return this._rush;
	}

	public get productTax(): Nullable<number> {
		return this._productTaxValue;
	}

	public get serviceTax(): Nullable<number> {
		return this._serviceTaxValue;
	}

	public get serviceTotal(): number {
		return this._serviceValue ?? 0;
	}

	public get productTotal(): number {
		return this._productValue ?? 0;
	}

	public get productTaxTotal(): number {
		return this._productTaxValue;
	}

	public get productTaxPercentage(): number {
		return this._productTaxPercentage;
	}

	public get serviceTaxTotal(): number {
		return this._serviceTaxValue;
	}

	public get serviceTaxPercentage(): number {
		return this._serviceTaxPercentage;
	}

	public get taxTotal(): number {
		return this.serviceTaxTotal + this.productTaxTotal;
	}

	public get subtotal(): number {
		if (this._subtotal !== null) return this._subtotal;

		return 0;
	}

	public get total(): number {
		if (this._total !== null) return this._total;

		return 0;
	}
}
