import { Action } from 'redux';

import { Nullable } from '@common/typescript/objects/Nullable';

export enum TypeKeys {
	SETLOCALSTATE = 'SETLOCALSTATE',
}

export enum LocalStateActions {
	NeedUpdate = 0,
}

export interface LocalStateStore {
	[value: string]: Nullable<LocalStateActions>;
}

const defaultLocalStore: LocalStateStore = {};

interface SetLocalStoreStateAction extends Action<TypeKeys.SETLOCALSTATE> {
	key: keyof LocalStateStore;
	type: TypeKeys.SETLOCALSTATE;
	payload: Nullable<LocalStateActions>;
}

type KnownLocalStoreActions = SetLocalStoreStateAction

export function getLocalStoreStateActionCreators() {
	return {
		setLocalState: (key: keyof LocalStateStore, value: Nullable<LocalStateActions>) => (dispatch) => {
			return dispatch({
				type: TypeKeys.SETLOCALSTATE,
				key,
				payload: value,
			});
		},
	};
}

export function getLocalStoreReducer() {
	return (state: LocalStateStore = defaultLocalStore, action: KnownLocalStoreActions) => {
		let result = state;

		if (action.type === TypeKeys.SETLOCALSTATE) {
			const newState = { ...state };

			newState[action.key] = action.payload;

			result = newState;
		}

		return result;
	};
}
