import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Nullable } from '@common/typescript/objects/Nullable';

import { ApplicationState } from '@app/store';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { selectListRequest } from '@app/store/SelectList/SelectListRequests';
import { Country } from '@app/objects/Country';

export const useCountry = (code?: string, id?: number): Nullable<Country> => {
	const dispatch = useDispatch();
	const factory = bindActionCreators(getActionCreators('countries', selectListRequest.countries), dispatch);
	const countries = useSelector((state: ApplicationState) => state.selects.countries.items);

	React.useEffect(() => {
		if (!countries.length) factory.request(null);
	}, [countries]);

	return React.useMemo(() => countries.find((item) => item.id === id || item.alpha3 === code), [countries, id, code]);
};
