import { WithId } from '@common/typescript/objects/WithId';
import { WithRemoved } from '@common/typescript/objects/WithRemoved';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Clinic } from '@app/objects/Clinic';
import { Location } from '@app/objects/Location';

export enum AttachedRouteClinic {
	Attached = 0,
	All = 1,
}

export interface Route extends WithId {
	name: string;
	description: string;
	locations: Array<RouteLocation>;
}

export interface RouteLocation extends WithId, WithRemoved {
	clinic: Nullable<Clinic>;
	clinicId: number;
	routeId: number;
	locationId: Nullable<number>,
	location: Nullable<Location>,
	order: number,
}
