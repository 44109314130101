import user from '@app/images/icons/user.svg';
import edit from '@app/images/icons/edit.svg';
import gender from '@app/images/icons/gender.svg';
import color from '@app/images/icons/color.svg';
import weight from '@app/images/icons/weight.svg';
import calendar from '@app/images/icons/calendar.svg';
import fish from '@app/images/icons/fish.svg';
import qrCode from '@app/images/icons/qr-code.svg';
import phone from '@app/images/icons/phone.svg';
import letter from '@app/images/icons/letter.svg';
import house from '@app/images/icons/house.svg';
import dog from '@app/images/icons/dog.svg';
import cat from '@app/images/icons/cat.svg';
import unicorn from '@app/images/icons/unicorn.svg';
import qrTable from '@app/images/icons/qr-code-table.svg';
import rush from '@app/images/icons/rush.svg';
import hold from '@app/images/icons/hold.svg';
import mapPin from '@app/images/icons/map-pin.svg';

import rushA from '@app/images/icons/allans-rush.svg';
import holdA from '@app/images/icons/allans-onhold.svg';

export {
	user,
	edit,
	gender,
	color,
	weight,
	calendar,
	fish,
	qrCode,
	phone,
	letter,
	house,
	dog,
	cat,
	unicorn,
	qrTable,
	rush,
	hold,
	mapPin,

	rushA,
	holdA,
};
