import * as Yup from 'yup';

import {
	idValidator,
	stringValidator,
	emailValidator,
	positiveNumberValidator,
	enumValidator, phoneValidator,
} from '@app/utilities/validators';
import { PriceKind, PriceType } from '@app/objects/Price';
import { DeliveryType, deliveryTypeManager, PetPrice } from '@app/objects/Pet';
import { CrematoryServiceTypePreferences } from '@app/objects/Crematory';
import { allowAdd } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';

const isClinicDelivery = (deliveryType: DeliveryType) => deliveryType === DeliveryType.Clinic;

const urnSchema = Yup.array().of(
	Yup.object().shape({
		count: Yup.number().when('done', {
			is: (val) => val === false,
			then: () => Yup.number().min(1, 'Count should be a positive value'),
		})
			.required('Count is required filed')
			.integer('Count quantity should be an integer value'),
		id: Yup.number().when(['entry', 'nodeId', 'removed'], {
			is: (entry, nodeId, removed) => !removed && !allowAdd(entry, nodeId),
			then: () => Yup.number().max(-3, 'Not all parameters for the urn are selected'),
		}),
	}),
);

export const petValidationSchema = (preferences: CrematoryServiceTypePreferences, country?: string) => Yup.object().shape({
	crematoryId: idValidator('Crematory'),

	ownerFirstName: Yup.string().nullable(),
	ownerLastName: stringValidator('Owner last name'),
	ownerEmail: emailValidator('Owner email', false),
	ownerPhone: phoneValidator('Owner phone', false, country),
	ownerPhone2: phoneValidator('Owner phone 2', false, country),

	name: stringValidator('Pet name'),
	petSpecieId: idValidator('Species'),
	petBreedId: idValidator('Pet breed'),
	clinicId: Yup.number()
		.when('priceType', {
			is: (priceType) => +priceType === PriceType.Wholesale,
			then: () => idValidator('Clinic'),
			otherwise: () => Yup.number().nullable(),
		}),
	receivedDate: positiveNumberValidator('Received date'), // Less than now?
	reportedWeight: positiveNumberValidator('Reported weight'),
	actualWeight: Yup.number().min(0, 'Actual Weight should not be negative'),
	genderId: idValidator('Gender'),

	deliveryType: enumValidator('Delivery type'),
	deliveryAddress: Yup.string().when('deliveryType', {
		is: (type) => !deliveryTypeManager.isNone(type) && !deliveryTypeManager.isPickup(type),
		then: () => stringValidator('Delivery address'),
	}),
	deliveryCity: Yup.string().when('deliveryType', {
		is: (type) => !deliveryTypeManager.isNone(type) && !deliveryTypeManager.isPickup(type),
		then: () => stringValidator('Delivery city'),
	}),
	deliveryStateId: Yup.number().when('deliveryType', {
		is: (type) => !deliveryTypeManager.isNone(type) && !deliveryTypeManager.isPickup(type),
		then: () => Yup
			.number()
			.min(1, 'Delivery State is required')
			.required('Delivery State is required'),
		otherwise: () => Yup.number().nullable(),
	}),
	deliveryZip: Yup.string().when('deliveryType', {
		is: (type) => !deliveryTypeManager.isNone(type) && !deliveryTypeManager.isPickup(type),
		then: () => stringValidator('Delivery zip'),
	}),
	deliveryClinicId: Yup.number().nullable().when('deliveryType', {
		is: isClinicDelivery,
		then: () => idValidator('Delivery clinic'),
	}),
	urns: Yup.array().when({
		is: (val: Array<PetPrice>) => val.filter((i) => !i.removed).length, // check the length of urns - only !removed urn
		then: () => urnSchema, // if urns.length > 0 - simple validation
		otherwise: () => Yup.array().when('priceType', { // if urns.length === 0 - check the requiresUrn flag in crematory preferences
			is: (priceType: PriceType) => (priceType === PriceType.Retail ? preferences.retailRequiresUrn : preferences.wholesaleRequiresUrn),
			then: () => Yup.array().when({ // if requiresUrn === true - validate min length of urns
				is: (val) => val.length > 0, // find min length
				then: () => urnSchema.min(
					Yup.ref('urns'), // take urns.length because we already check array for removed urns & ignore it - [removed, removed] === []
					'For this combination of service and price types required at least one urn',
				),
				otherwise: () => urnSchema.min(
					1, // take 1 because urn.length === 0
					'For this combination of service and price types required at least one urn',
				),
			}),
			otherwise: () => urnSchema, // if requiresUrn === false - no validate urns length
		}),
	}),
	services: Yup.array().of(
		Yup.object().shape({
			count: Yup.number()
				.required('Count is required filed')
				.min(0, 'Count should be a positive value')
				.integer('Count quantity should be an integer value'),
			value: Yup.number().when(['price.priceKind'], {
				is: (priceKind: PriceKind) => priceKind !== PriceKind.Discount && priceKind !== PriceKind.UrnPrice,
				then: () => Yup.number().min(0, 'Cost should be a positive value'),
			}).required('Cost is required filed'),
		}),
	),
	internalIdNum: Yup.string().when('id', {
		is: () => preferences?.showInternalId && preferences?.requireInternalId,
		then: () => stringValidator('Internal ID'),
	}),
});
