import { Nullable } from '@common/typescript/objects/Nullable';

import {
	Price,
	PriceKind,
} from '@app/objects/Price';
import {
	KeyPriceParamsContainer,
	shouldKeepPrice,
} from '@app/components/Utils/Prices/Helpers';
import { DeliveryType, PetPrice } from '@app/objects/Pet';

import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { ExistingPriceStack } from '@app/services/pricing/ExistingPriceStack';
import { CalculatedPriceStack } from '@app/services/pricing/CalculatedPriceStack';
import { ClinicDiscount } from '@app/objects/Clinic';
import { TaxPolicy } from '@app/objects/Crematory';

export interface Values extends KeyPriceParamsContainer {
	id: number;
	services?: Array<PetPrice>;
	recalculatePrice: boolean;
	deliveryType: DeliveryType;
	products?: Array<PetPrice>;
	clinicDiscounts: Nullable<Array<PetPrice>>;
}

export class PricingManager {
	public getPrice(
		current: Values,
		initial: Values,
		prices: Array<Price>,
		clinicDiscounts: Array<ClinicDiscount>,
		taxPolicy: TaxPolicy,
	): IPriceStack {
		const list = current.services ?? [];
		const basePrice: Nullable<PetPrice> = list.find((item: PetPrice) => item.price?.priceKind === PriceKind.BasePrice);
		const keepPrice: boolean = shouldKeepPrice(initial, current) && !current.recalculatePrice;

		if (current.id > 0 && keepPrice && basePrice) {
			return new ExistingPriceStack(list, current);
		}

		return new CalculatedPriceStack(prices, current.services ?? [], current.products ?? [], current, clinicDiscounts, taxPolicy);
	}
}
