import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Space from 'antd/lib/space';
import { CommentOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd/lib/grid';

import { Loading } from '@common/react/components/UI/Loading/Loading';
import TabsWithUrl from '@common/react/components/UI/TabsWithUrl/TabsWithUrl';
import { loadable, loadableDelay, params } from '@common/react/loadable/loadableSettings';

import { LinedText } from '@app/components/UI/LinedText/LinedText';
import { barColor } from '@app/objects/Crematory';
import {
	getServiceTypeName,
	Pet,
	PetPrice,
} from '@app/objects/Pet';
import { ApplicationState } from '@app/store';
import { ValueDisplay } from '@app/components/UI/ValueDisplay/ValueDisplay';
import { PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';
import { SpecificContent } from '@app/components/UI/Status/StatusModal/SpecificContent';
import { bindItemActionCreators } from '@app/store/Item';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';
import { australiaCode } from '@app/components/UI/Inputs/PhoneInput';
import { useCountry } from '@app/hooks/useCountry';

const LoadableCommentTree = loadable(() => loadableDelay(import('@common/react/components/Forms/Comment/CommentTree')), params);

interface ModalContentProps {
	code: PromoteResponseCode;

	onChange?: (item: Partial<Pet>, params?: PromoteParams) => void;
	onClose: () => void;
}

function merge(oldItem: Pet, newItem: Partial<Pet>): Pet {
	const services = oldItem.services ? [...oldItem.services] : [];
	if (newItem.services && newItem.services.length) {
		newItem.services.forEach((item: PetPrice) => {
			const id = services.findIndex((test: PetPrice) => test.id === item.id);
			if (id >= 0) {
				services[id] = { ...services[id], ...item };
			} else {
				services.push(item);
			}
		});
	}

	return { ...oldItem, ...newItem, services };
}

export const ModalContent: React.FC<ModalContentProps> = (props: ModalContentProps) => {
	const state = useSelector((state: ApplicationState) => state.petModal);
	const dispatch = useDispatch();
	const factory = bindItemActionCreators<Pet>(dispatch);
	const { t } = useTranslation();

	const item = state.item;
	const country = useCountry(item?.crematory?.country?.alpha3, item?.crematory?.countryId);
	const isAustralia = React.useMemo(() => country?.phoneCode === australiaCode, [country]);

	const onChange = (item: Partial<Pet>, shouldClose: boolean = true, params?: PromoteParams) => {
		factory.mergeItem('petModal', item, merge);
		factory.mergeItem('pet', item, merge);
		props.onChange?.(item, params);

		if (shouldClose) props.onClose();
	};

	if (item === null || state.isLoading) return <Loading />;

	return (
		<TabsWithUrl
			className="crematory-tabs"
			defaultKey="info"
			tabsUrlKey="modal-tab"
			items={[
				{
					key: 'info',
					label: (
						<Space>
							<InfoCircleOutlined />
							Pet Information
						</Space>
					),
					children: (
						<div>
							<LinedText
								color={barColor(item.serviceType, item.isSpecial, item.crematory)}
								text={getServiceTypeName(item.serviceType, t, item.isSpecial, isAustralia, item.rush)}
								className="form-group"
								strong
							/>
							<Row gutter={[30, 16]}>
								<Col xs={24} sm={24} md={12}>
									<ValueDisplay title="Pet Name" value={`${item.name} ${item.ownerLastName}`} />
								</Col>
								<Col xs={24} sm={24} md={12}>
									<ValueDisplay title={t('modals.pet-info-modal.labels.color')} value={item.color} />
								</Col>
							</Row>
							<Row gutter={[30, 16]}>
								<Col xs={24} sm={24} md={12}>
									<ValueDisplay title="Pet Species" value={item.petSpecie?.name} />
								</Col>
								<Col xs={24} sm={24} md={12}>
									<ValueDisplay title="Pet Breed" value={item.petBreed?.name} />
								</Col>
							</Row>
							<SpecificContent
								code={props.code}
								item={item}
								onChange={onChange}
								onClose={props.onClose}
							/>
						</div>
					),
				}, {
					key: 'notes',
					label: (
						<Space>
							<CommentOutlined />
							Notes
						</Space>
					),
					children: (
						<div className="card__body editor-page">
							<LoadableCommentTree
								objectType="pet"
								objectId={item.id}
								withReply={false}
								withoutCancelButton
							/>
						</div>
					),
				},
			]}
		/>
	);
};
